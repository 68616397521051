export const ADD_USER_WISHLIST = "app/UserWishList/ADD_USER_WISHLIST";
export const ADD_USER_WISHLIST_SUCCESS = "app/UserWishList/ADD_USER_WISHLIST_SUCCESS";
export const ADD_USER_WISHLIST_FAILURE = "app/UserWishList/ADD_USER_WISHLIST_FAILURE";


export const GET_USER_WISHLIST = "app/UserWishList/GET_USER_WISHLIST";
export const GET_USER_WISHLIST_SUCCESS = "app/UserWishList/GET_USER_WISHLIST_SUCCESS";
export const GET_USER_WISHLIST_FAILURE = "app/UserWishList/GET_USER_WISHLIST_FAILURE";

export const REMOVE_USER_WISHLIST = "app/UserWishList/REMOVE_USER_WISHLIST";
export const REMOVE_USER_WISHLIST_SUCCESS = "app/UserWishList/REMOVE_USER_WISHLIST_SUCCESS";
export const REMOVE_USER_WISHLIST_FAILURE = "app/UserWishList/REMOVE_USER_WISHLIST_FAILURE";

export const RESET_TO_INITIAL_STATE = "APP/WISHLIST/RESET_TO_INITIAL_STATE";