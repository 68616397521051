import React, {useEffect} from 'react'
import Footer from '../../Components/Footer/Footer';
import '../../css/returnpolicy.css';

function index() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
    <section className='return-policy-section'>
        <div className='container'>
            <div className='row'>
                <div className='col-md-12'>
                <h2 className="footer-inner-page-subheading">
                RETURN & EXCHANGE
                </h2>
                    <div className='return-box'>
                    <p className='return-para'>
                        At Modern House of antiques, we take pride in offering authentic vintage and antique decoratives. 
                        Due to the nature of these items, some signs of aging and wear are to be expected. We make every 
                        effort to provide clear, detailed descriptions and high-quality images to help you understand the 
                        condition of each piece.</p>
                        <h2 className="footer-inner-page-subheading">Our Commitment:</h2>
                        <br/>
                        <h3  className="footer-inner-page-subheading">
                            1. Shipping Damage
                        </h3>
                        <p className='return-para'>
                            If your purchase is damaged during transit, 
                            please contact us within 48 hours of delivery. We will guide you through 
                            the return process and issue a full refund once the item is returned to us.</p>
                        <h3  className="footer-inner-page-subheading">
                            2. Item Not as Described
                        </h3>
                            <p className='return-para'>
                            We understand that buying vintage and antiques online requires trust. If the item you receive does not match our description or images, you can return it in its original packaging within 7 days for a full refund or exchange.</p>
                            <p className='return-para'>
                            We strive to make your experience as smooth as possible and are happy to assist with any concerns you may have. Your satisfaction is our priority, and we’re here to help ensure you are delighted with your purchase. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default index