import React, { useEffect, useState } from "react";
import "../../css/instagramFeed.css";
import { ErrorBoundary } from "react-error-boundary";
import axios from "axios";

const InstagramFeed = () => {
  const [loading,setLoading] = useState(true);
  const [instaFeeds,setInstafeeds] = useState([]);
  const postCount = 6;

  useEffect(() => {
    getInstagramFeeds();
  }, []);

  const getInstagramFeeds = async() =>{
    try{
      const response  = await axios.get(`https://graph.instagram.com/me/media?fields=media_count,media_type,permalink,media_url,caption,thumbnail_url&access_token=${process.env.REACT_APP_INSTA_TOKEN}`);
      if(response && response.status == 200 && response.data && response.data.data){
        let allPosts = [...response.data.data].filter((el) => el.media_url);

        if(Array.isArray(allPosts) && allPosts.length > postCount){
          allPosts = allPosts.slice(0, postCount);
        }

        setInstafeeds(allPosts);
      }
    }
    catch(error){
      console.warn(error.message || "Unable to get instagram feeds.")
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <ErrorBoundary fallback={<></>}>
      {!loading && instaFeeds && instaFeeds.length > 0 && (
        <section className="homepage-follow-modern-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="follow-up-email-text">
                  <h4 className="common-heading">
                    Follow @modernhouseofantiques
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div>
                <div className="_2R-kh">
                  {instaFeeds.map((item,index)=>{
                    return(
                      <div className="_wPDyp" key={'MHA_INSTA_FEED'+ (index + 1)}>
                        <a
                          href={item.permalink || "https://www.instagram.com/modernhouseofantiques/"}
                          className="ig-instagram-link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="_vzTHL"
                            src={item.media_type === "IMAGE" || item.media_type === "CAROUSEL_ALBUM" ? item.media_url || "" : item.media_type === "VIDEO" ? item.thumbnail_url || "" : item.media_url || ""  }
                            alt={item.caption || "description"}
                          />
                          <div className="_3xnQP">
                            <div className="instagram-count-item">
                              <span className="icon">
                                <svg height="18" viewBox="0 0 512 512" width="18">
                                  <path
                                    fill="currentColor"
                                    d="m256 386c-71.683 0-130-58.317-130-130 7.14-172.463 252.886-172.413 260 .001 0 71.682-58.317 129.999-130 129.999zm0-220c-49.626 0-90 40.374-90 90 4.944 119.397 175.074 119.362 180-.001 0-49.625-40.374-89.999-90-89.999zm236 346h-472c-11.046 0-20-8.954-20-20v-472c0-11.046 8.954-20 20-20h472c11.046 0 20 8.954 20 20v472c0 11.046-8.954 20-20 20zm-452-40h432v-432h-432zm372-392c-11.046 0-20 8.954-20 20 0 11.046 8.954 20 20 20 11.046 0 20-8.954 20-20 0-11.046-8.954-20-20-20z"
                                  ></path>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </ErrorBoundary>
  );
};

export default InstagramFeed;
