export const GET_PRODUCTS = "APP/PRODUCTSPAGE/GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "APP/PRODUCTSPAGE/GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "APP/PRODUCTSPAGE/GET_PRODUCTS_FAILURE";

export const GET_PRODUCTS_BY_CATEGORIES = "APP/GET_PRODUCTS_BY_CATEGORIES";
export const GET_PRODUCTS_BY_CATEGORIES_SUCCESS = "APP/GET_PRODUCTS_BY_CATEGORIES_SUCCESS";
export const GET_PRODUCTS_BY_CATEGORIES_FAILURE = "APP/GET_PRODUCTS_BY_CATEGORIES_FAILURE";


export const GET_PRODUCTS_PRICE_BY_PRODUCT_ID = "APP/PRODUCTSPAGE/GET_PRODUCTS_PRICE_BY_PRODUCT_ID";
export const GET_PRODUCTS_PRICE_BY_PRODUCT_ID_SUCCESS = "APP/PRODUCTSPAGE/GET_PRODUCTS_PRICE_BY_PRODUCT_ID_SUCCESS";
export const GET_PRODUCTS_PRICE_BY_PRODUCT_ID_FAILURE = "APP/PRODUCTSPAGE/GET_PRODUCTS_PRICE_BY_PRODUCT_ID_FAILURE";

export const RESET_PRODUCT_LIST_TO_INITIAL_STATE = "APP/PRODUCTSPAGE/RESET_PRODUCT_LIST_TO_INITIAL_STATE"