import React from 'react'
import { Link} from 'react-router-dom'
import "../../css/pageNotFound.css"
import Logo from "../../images/logo.jpg"

const PageNotFound = () => {
  return (
    <>
       <section className="error-page">
       <div className="container error-page-container">
           <div className="row">
                <div className="col-lg-12 text-center">
                      <img src={Logo} alt="Logo" width="210" />
                      <div className="error-page-wrapper">
                          <h1>
                              <span className="one">4</span>
                              <span className="two">0</span>
                              <span className="one">4</span>
                          </h1>
                          <p className="error-page-msg">
                            We are sorry, but the page you requested was not found
                          </p>
                          <div className="error-page-gohome-link">
                            <a href='/' className="error-page-btn home">Go Home</a>
                            <Link to="/contactus" className="error-page-btn contact">Contact Us</Link>
                          </div>
                      </div>
                </div>
           </div>
       </div>
    </section> 
    </>
  )
}

export default PageNotFound