import React, { useEffect, useState } from "react";
import "../../css/Common.css"
import { useLocation, useNavigate } from "react-router-dom";
import ComingSoon from "../../Components/ComingSoon";

export let Pagination = (props) => {
  
  let location = useLocation();
  let navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const isInteger = str => parseInt(str) == str;
  const pageNo = queryParams.get('page');

  let { totalCount, getProductsOfSelectedPage, products, loading } = props;
  let [pages, setPages] = useState();
  let [currentPage, setCurrentPage] = useState(pageNo ? pageNo : 1);

  useEffect(() => {
    let result = [];
    for (let i = 0; i < totalCount; i++) {
      result.push(i + 1);
    }
    
    setPages(result);
  }, [totalCount]);


  let getSelectedPage = (page) => {
    if (page === "previous") {
      page = Number(currentPage) - 1;
    } else if (page === "next") {
      page = Number(currentPage) + 1;
    }
    setCurrentPage(page);
    navigate({search: `?page=${page}` },{state: location.state ? location.state : null});
    
  };

  useEffect(() => {
    if (currentPage) {
      getProductsOfSelectedPage(currentPage);
    }
  }, [currentPage]);

  useEffect(()=>{
    if(currentPage != (pageNo || 1)){
      setCurrentPage(pageNo || 1)
    }
  },[pageNo])

  if((!products || !products.length > 0) && loading){
    return (<></>)
  }

  if( !currentPage  ||  !isInteger(currentPage) ||  Number(currentPage) < 0){
    return <ComingSoon/>
  }

  return (
    <div className="container">
      <hr />
     { products && products.length > 0 ?
     <ul className="pagination pagination-lg">
        {currentPage > 1 && (
          <li className="page-item" onClick={() => getSelectedPage("previous")}>
            <button type="button" className="page-link">
              Previous
            </button>
          </li>
        )}
        {pages &&
          pages.map((item, i) => {
            return (
              <li
                key={i}
                className={`${currentPage == i + 1 && "activePage"} page-item `}
                onClick={() => getSelectedPage(item)}
              >
                <button type="button" className="page-link">
                  {item} 
                </button>
              </li>
            );
          })}
        {currentPage != totalCount && (
          <li className="page-item" onClick={() => getSelectedPage("next")}>
            <button type="button" className="page-link">
              Next
            </button>
          </li>
        )}
      </ul>
      :
      <ComingSoon/>
      }
    </div>
  );
};