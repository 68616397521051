export const GET_PRODUCTS_DETAILS = "APP/PRODUCT_DETAILS_PAGE/GET_PRODUCTS_DETAILS";
export const GET_PRODUCTS_DETAILS_SUCCESS = "APP/PRODUCT_DETAILS_PAGE/GET_PRODUCTS_DETAILS_SUCCESS";
export const GET_PRODUCTS_DETAILS_FAILURE = "APP/PRODUCT_DETAILS_PAGE/GET_PRODUCTS_DETAILS_FAILURE";

export const ADD_PRODUCT_TO_CART = "APP/PRODUCT_DETAILS_PAGE/ADD_PRODUCT_TO_CART";
export const ADD_PRODUCT_TO_CART_SUCCESS = "APP/PRODUCT_DETAILS_PAGE/ADD_PRODUCT_TO_CART_SUCCESS";
export const ADD_PRODUCT_TO_CART_FAILURE = "APP/PRODUCT_DETAILS_PAGE/ADD_PRODUCT_TO_CART_FAILURE";

export const ADD_ITEMS_TO_CART = "APP/PRODUCT_DETAILS_PAGE/ADD_ITEMS_TO_CART";
export const ADD_ITEMS_TO_CART_SUCCESS = "APP/PRODUCT_DETAILS_PAGE/ADD_ITEMS_TO_CART_SUCCESS";
export const ADD_ITEMS_TO_CART_FAILURE = "APP/PRODUCT_DETAILS_PAGE/ADD_ITEMS_TO_CART_FAILURE";

export const GET_PRODUCT_MULTIPLE_IMAGES = "APP/PRODUCT_DETAILS_PAGE/GET_PRODUCT_MULTIPLE_IMAGES";
export const GET_PRODUCT_MULTIPLE_IMAGES_SUCCESS = "APP/PRODUCT_DETAILS_PAGE/GET_PRODUCT_MULTIPLE_IMAGES_SUCCESS";
export const GET_PRODUCT_MULTIPLE_IMAGES_FAILURE = "APP/PRODUCT_DETAILS_PAGE/GET_PRODUCT_MULTIPLE_IMAGES_FAILURE";

export const GET_SIMILAR_PRODUCTS_LIST = "APP/CART_PAGE/GET_SIMILAR_PRODUCTS_LIST";
export const GET_SIMILAR_PRODUCTS_LIST_SUCCESS = "APP/CART_PAGE/GET_SIMILAR_PRODUCTS_LIST_SUCCESS";
export const GET_SIMILAR_PRODUCTS_LIST_FAILURE = "APP/CART_PAGE/GET_SIMILAR_PRODUCTS_LIST_FAILURE";

export const GET_SIMILAR_PRODUCT_BY_ID = "APP/CART_PAGE/GET_SIMILAR_PRODUCT_BY_ID";
export const GET_SIMILAR_PRODUCT_BY_ID_SUCCESS = "APP/CART_PAGE/GET_SIMILAR_PRODUCT_BY_ID_SUCCESS";
export const GET_SIMILAR_PRODUCT_BY_ID_FAILURE = "APP/CART_PAGE/GET_SIMILAR_PRODUCT_BY_ID_FAILURE";


export const ADD_PRODUCT_QUANTITY_TO_CART = "APP/PRODUCT_DETAILS_PAGE/ADD_PRODUCT_QUANTITY_TO_CART";


export const RESET_TO_INITIAL_STATE = "APP/PRODUCT_DETAILS_PAGE/RESET_TO_INITIAL_STATE"